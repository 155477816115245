@font-face {
  font-family: 'oranienbaum';
  src: url('./fonts/Oranienbaum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'rota';
  src: url('./fonts/Rota-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}